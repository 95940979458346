import React from "react"
import {

  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,

} from "reactstrap"
import PropTypes from 'prop-types'
import * as Icon from "react-feather"

import { history } from "../../../history"
import { Logout } from '../../../redux/actions/Login/loginActions'
import { connect } from 'react-redux'


class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: []
  }

  logout = () => {
    this.props.Logout()
    history.push("/pages/login")
  }

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">

        <span className="align-middle" style={{cursor:"pointer"}} onClick={() => this.logout()}>Log Out</span>
        {/* 
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <span data-tour="user">
              <img
                src={this.props.userImg}
                className="round"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <DropdownMenu right>
          <DropdownItem tag="a" href="#">
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle">Edit Profile</span>
          </DropdownItem>
         
          <DropdownItem
            tag="a"
            href="#"
            onClick={()=>this.logout()}
          >
          <Icon.Power size={14} className="mr-50" />
          <span className="align-middle">Log Out</span>
        </DropdownItem>
      </DropdownMenu>

        </UncontrolledDropdown> */}
      </ul>
    )
  }
}
NavbarUser.propTypes = {
  Logout: PropTypes.func.isRequired,
}

export default connect(null, { Logout })(NavbarUser)
