import { combineReducers } from "redux";
import customizer from "./customizer/";
import Login from './login/loginReducer';
import auth from "./auth/";
import alert from './Alert/alert';
import LoaderReducer from './loaderReducer/LoaderReducer';

const rootReducer = combineReducers({
  customizer: customizer,
  auth:auth,
  login:Login,
  alert: alert,
  LoaderReducer,
});

export default rootReducer
