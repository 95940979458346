import { LOGIN_SUCCESS, LOG_OUT, FAILURE } from './index'
// import Axios from 'axios'
import AxiosInstance from '../../../api/API';

//All Products
export const LoginUsers = (email, password) => async dispatch => {
    try {

        //    const res = await Axios({
        //         method: 'post',

        //         url: process.env.REACT_APP_ADMIN_API_HOST+'auth/login',
        //         headers:{
        //             'Content-Type': 'application/json'
        //         },
        //         data: JSON.stringify({
        //             email: email,
        //             password: password
        //         })
        //     })

        const res = await AxiosInstance.post('admin/login', {
            body: {
                email: email,
                password: password
            }
        });
        if (res && res.responseCode===200 ) {
            localStorage.setItem('token', res.result.token)
            localStorage.setItem('adminid', res.result ? res.result.userId ? res.result.userId : null : null)

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.result,
                token: res.result.token
            })
        }

    }
    catch (err) {
        dispatch({
            type: FAILURE,
            msg: 'Something went wrong'
        })
    }

}
export const RegisterUser = (email, password) => async dispatch => {
    try {
        // const res = await Axios({
        //     method: 'post',
        //     url: process.env.REACT_APP_ADMIN_API_HOST + 'auth/register',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: JSON.stringify({
        //         email: email,
        //         password: password
        //     })
        // })
        // dispatch(setAlert("Register Successfully", 'success'));
        // dispatch({
        //     type: REGISTER_SUCCESS,

        // })
    }
    catch (err) {
        dispatch({
            type: FAILURE,
            msg: 'Something went wrong'
        })
    }

}
export const Logout = () => async dispatch => {
    try { dispatch({ type: LOG_OUT }) }
    catch (err) {
        dispatch({
            type: FAILURE,
            msg: 'Something went wrong'
        })
    }
}
