import { START_LOADER, STOP_LOADER } from '../../actions/ActionTypes';

const initialState = {
    isLoading:false
};

const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_LOADER: return {...state, isLoading:true};
        case STOP_LOADER: return {...state, isLoading:false};
        default: return state;
    }
}

export default LoaderReducer;