export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';

export const ADMIN_LAYOUT = '/admin'
export const DASHBOARD = '/dashboard';

export const USER_LISTING = '/user-listing';
export const ADD_USER = '/user-add';
export const EDIT_USER = '/user-edit';
export const VIEW_USER = '/user-view';

export const GRADE_LISTING = '/grade-listing';
export const ADD_GRADE = '/grade-add';
export const EDIT_GRADE = '/grade-edit';
export const VIEW_GRADE = '/grade-view';

export const SCHOOL_LISTING = '/school-listing';
export const ADD_SCHOOL = '/school-add';
export const EDIT_SCHOOL = '/school-edit';
export const VIEW_SCHOOL = '/school-view';

export const LANGUAGE_LISTING = '/medium-listing';
export const ADD_LANGUAGE = '/medium-add';
export const EDIT_LANGUAGE = '/medium-edit';
export const VIEW_LANGUAGE = '/medium-view';

export const SUBJECT_LISTING = '/subject-listing';
export const ADD_SUBJECT = '/subject-add';
export const EDIT_SUBJECT = '/subject-edit';
export const VIEW_SUBJECT = '/subject-view';

export const CHAPTER_LISTING = '/chapter-listing';
export const ADD_CHAPTER = '/chapter-add';
export const EDIT_CHAPTER = '/chapter-edit';
export const VIEW_CHAPTER = '/chapter-view';
export const BULK_UPLOAD_CHAPTER = '/chapter-bulk-upload';

export const TEST_CREATE = '/test-create';
export const TEST_LISTING = '/test-list';
export const TEST_EDIT = '/test-edit';
export const BULK_UPLOAD_TEST = '/test-bulk-upload';
export const BULK_UPLOAD_NOTES = '/bulk-upload-notes';

export const NOTES_LISTING = '/notes-listing';
export const CREATE_NOTES = '/notes-add';
export const EDIT_NOTES = '/notes-edit';
export const BULK_UPLOAD_VIDEO = '/video-bulk-upload';

export const AUDIO_LISTING = '/audio-listing';
export const CREATE_AUDIO = '/audio-add';
export const EDIT_AUDIO = '/audio-edit';
export const BULK_UPLOAD_AUDIO = '/audio-bulk-upload';

export const VIDEO_LISTING = '/video-listing';
export const CREATE_VIDEO = '/video-add';
export const EDIT_VIDEO = '/video-edit';

export const EBOOK_LISTING = '/e-book-listing';
export const CREATE_EBOOK = '/e-book-add';
export const EDIT_EBOOK = '/e-book-edit';
export const EBOOK_BULK_UPLOAD = '/ebook-bulk-upload';

export const DISTRIBUTOR_LISTING = '/distributor';
export const CREATE_DISTRIBUTOR = '/add';
export const EDIT_DISTRIBUTOR = '/edit';
export const ASSIGN_COUPONS = '/coupons-assign';
export const ADD_COUPONS = '/coupons-add';


export const QUESTIONS_LISTING = '/questions';
export const ADD_ANSWER = '/add-answer';

export const NOTIFICATION_LISTING = '/notifications';
export const ADD_NOTIFICATION = '/add-notifications';

export const YOUTUBE_VIDEOS = '/youtube';
export const ADD_YOUTUBE_VIDEOS = '/add-youtube';
export const EDIT_YOUTUBE = '/youtube-edit';

export const RouterKeys = {
    "subjectId": "subjectId",
    "chapterId": "chapterId",
    "testId": "testId",
    "notesId": "notesId",
    "audioId": "audioId",
    "videoId": "videoId",
    "userId": "userId",
    "ebookId": "ebookId",
    "couponId": "couponId",
    "distributorId": "distributorId",
    "questionId": "questionId",
    "notificationId": "notificationId",
    "youtubeId": "youtubeId"
};

export const decodeSearchString = (key, search) => {
    if (search) {
        let params = new URLSearchParams(search);
        return params.get(key);
    }
    else {
        let params = new URLSearchParams(window.location.search);
        return params.get(key);
    }
}
