import axios from 'axios';
import { startLoader, stopLoader } from '../redux/actions/loaderActions/LoaderAction';
import { store } from '../redux/storeConfig/store';

import { Logout } from '../redux/actions/Login/loginActions';

// import { toast_func } from '../commonComponents/toasterFunc/ToasterFunc';

// const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const baseAPIUrl = process.env.REACT_APP_BACKEND_BASE_URL;

// let isRefreshing = false;

// class ApiError extends Error {
//     constructor(message, code) {
//         super(message);
//         this.code = code;
//     }
// }

// const getUser = () => {
//     return JSON.parse(localStorage.getItem('user'));
// }

const getTokenFromLocalStorage = () => {
    return localStorage.getItem('token');
}

// const saveUser = user => {
//     localStorage.setItem('user', JSON.stringify(user));
// }

// const deleteUser = () => {
//     localStorage.removeItem('user');
// }


const axiosInstance = axios.create({
    baseURL: baseAPIUrl,
});

// let isAlreadyFetchingAccessToken = false; //state that will tell us if an api is requesting for new access token

// let subscribers = []; // queue containing all pending requests for refresh token

// *************************** function to call all pending api requests after the new access token is received ****************************

// function onAccessTokenFetched(token) {
//     subscribers.forEach((callback) => {
//         callback(token);
//     });
//     subscribers = [];
// }


// *************************** function to push all apis waiting for new access token  to a queue ****************************

// function addSubscriber(callback) {
//     subscribers.push(callback);
// }


// **************************** function to perform the reset access token action and reattempt the pending apis call ****************************

// async function resetTokenAndReattemptRequest(error, user) {
//     try {
//         const { config } = error
//         const originalRequest = config;

//         const retryOriginalRequest = new Promise(resolve => {
//             addSubscriber(token => {
//                 axiosInstance.defaults.headers['Authorization'] = `Bearer ${token}`;
//                 originalRequest.headers['Authorization'] = `Bearer ${token}`;
//                 resolve(axiosInstance(originalRequest));
//             });
//         });

//         if (!isAlreadyFetchingAccessToken) {
//             isAlreadyFetchingAccessToken = true;

//             const response = await instance.get('Authentication/refresh', {
//                 headers: {
//                     "x-refresh-token": user.refreshToken,
//                 }
//             });
//             if (response && response.status === 401) {
//                 // store.dispatch(signOut());
//                 return Promise.reject(error);
//             }
//             user['accessToken'] = response.authenticationResult?.accessToken;
//             user['idToken'] = response.authenticationResult?.idToken;
//             user = response;
//             saveUser(user);

//             isAlreadyFetchingAccessToken = false;

//             onAccessTokenFetched(response.token);
//         }
//         return retryOriginalRequest;
//     }
//     catch (err) {
//         return Promise.reject(err)
//     }
// }

// **************************** axios api request interceptor ****************************

axiosInstance.interceptors.request.use(function (config) {
    return new Promise(async resolve => {
        config.headers = Object.assign(config.headers || {}, {
            'content-type': 'application/json',
            'accept': 'application/json',
        });

        const token = getTokenFromLocalStorage();
        if (token && token !== "undefined" && token !== "null") {

            config.headers['token'] = `${token}`;
            resolve(config);
            // const user = await JSON.parse(userString);
            // if (user[ID_TOKEN_KEY]) {
            //     config.headers['Authorization'] = `Bearer ${user[ID_TOKEN_KEY]}`;
            // resolve(config);
            // }
            // else {
            //     setTimeout(() => {
            //         const user = JSON.parse(getUserFromLocalStorage());
            //         config.headers['Authorization'] = `Bearer ${user[ID_TOKEN_KEY]}`;
            //         resolve(config);
            //     }, 500);
            // }
        }
        else {
            resolve(config);
        }

    })


}, function (error) {

    return Promise.reject(error);
});

// **************************** axios api response interceptor **************************** //

axiosInstance.interceptors.response.use((response) => {
    if (response.data && response.data.responseCode === 401) {
        store.dispatch(Logout());
        return response.data;
    }
    return response.data;
}, async (error) => {
    // const { config } = error;
    // const originalRequest = config;

    // if (error.response && error.response.status === 401) {
    //     toast_func('info', 'Session expired. Please login again');
    //     store.dispatch(logoutUser());
    // }
    // if (error.response && error.response.status === 401) {
    //     let user = getUser();
    //     if (user && !error.config.url.includes("Authentication/refresh")) {
    //         user[ID_TOKEN_KEY] = null;
    //         saveUser(user);
    //         const data = await resetTokenAndReattemptRequest(error, user);
    //         return data;
    //     }
    //     else if (error.config.url.includes("Authentication/refresh")) {
    //         toast_func('info', 'Session expired. Please login again.');
    //         store.dispatch(logoutUser());
    //         // localStorage.clear();
    //         // window.location = "/";
    //         // window.location.reload();
    //     }
    // }
    return Promise.reject(error);
});

// **************************** function that will create all xhr api calls ****************************

export const makeRequest = async (methodName,
    path,
    { queryParameters, body, headers } = { queryParameters: {}, body: {}, headers: {} }) => {

    // below is the check for internet connection 
    if (navigator.onLine) {

        store.dispatch(startLoader())
        var complete = false;
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        let elapsed = 0;
        const interval = 100;

        const timeoutTimer = setInterval(() => {
            if (!complete && elapsed >= 30 * 1000 /*requestTimeout*/) {
                clearInterval(timeoutTimer);
                source.cancel('Request timed out');
            } else {
                elapsed += interval;
            }
        }, interval);

        try {
            var response;

            response = await axiosInstance.request({
                url: path,
                method: methodName,
                params: queryParameters,
                data: body,
                cancelToken: source.token,
                headers: headers
            });

            return response;
        } catch (error) {
            if (axios.isCancel(error)) {
                return new Error('Request timed out');
            }
            throw error;
        } finally {
            store.dispatch(stopLoader());
            complete = true;
            if (timeoutTimer) {
                clearInterval(timeoutTimer);
            }
        }
    }
}

// **************************** global instance of axios that can be called from anywhere in our application ****************************

const instance = {
    post: makeRequest.bind(null, 'post'),
    get: makeRequest.bind(null, 'get'),
    put: makeRequest.bind(null, 'put'),
    patch: makeRequest.bind(null, 'patch'),
    delete: makeRequest.bind(null, 'delete'),
};

export default instance;